import { Component, Input, OnChanges } from '@angular/core';
import { IDocument } from 'libraries/AonSDK/src/aon';
import { DocumentService } from 'src/app/core/services/document.service';
import { FileToBase64 } from 'src/app/core/utilities/file';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss']
})

export class FileViewerComponent implements OnChanges {
  // @Input() pdfSrc : IDocument = this.documentService.objectFactory.createDocument();
  @Input() pdfSrc : any;
  @Input() zoom   : string = 'page-width'
  @Input() locale : string = 'es-ES';
  @Input() fileName: string = '';
  fileBytes       : any;
  showErrorMessage: boolean = false;
  safeImageUrl    : SafeResourceUrl = ''
  spinnerLoadFile : boolean = true;

  constructor(private documentService: DocumentService, private sanitizer: DomSanitizer){

  }

  ngOnChanges(): void {
    this.fileBytes        = null;
    this.showErrorMessage = false;
    this.spinnerLoadFile  = true;

    this.documentService.getDocumentFile(this.pdfSrc).then((response) => {
      if (this.pdfSrc.FileType.split('/')[1] === 'pdf') {
        // PDF
        response.arrayBuffer().then((response) => {
          this.fileBytes  = new Uint8Array(response);
          this.fileName   = typeof this.pdfSrc.FileName === 'string' ? this.pdfSrc.FileName : String(this.pdfSrc.FileName);
        });
      } else {
        // Imagenes
        FileToBase64(new File([response], this.pdfSrc.FileName)).then((response) => {
          this.fileBytes = response;

          // Utiliza DomSanitizer para marcar la URL como segura
          this.safeImageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            'data:' + this.pdfSrc.FileType + ';base64,' + this.fileBytes
          );
          this.fileName = this.pdfSrc.FileName;
        });
      }

      this.spinnerLoadFile = false;
    },(error) => {
      this.showErrorMessage = true;
      this.spinnerLoadFile  = false;
    });
  }

  handleImageError() {
    // Manejar el error cuando la imagen no se puede cargar
    this.showErrorMessage = true;
  }
}
