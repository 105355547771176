import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { validateFormatNumber } from 'src/app/core/utilities/number';
import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { DateFormat } from 'src/app/core/utilities/time';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector    : 'app-input',
  templateUrl : './input.component.html',
  styleUrls   : ['./input.component.scss'],
})

export class InputComponent implements OnInit, OnChanges {
  @Output() inputValue    = new EventEmitter<any>();
  @Output() outputControl = new EventEmitter<UntypedFormControl>();
  @Input() id               : string  = '';
  @Input() type             : string  = '';
  @Input() appearance       : MatFormFieldAppearance = 'outline';
  @Input() width            : string  = '100%';
  @Input() label            : string  = '';
  @Input() hint             : string  = '';
  @Input() placeholder      : string  = '';
  @Input() value            : any     = '';
  @Input() options          : any;
  @Input() optionName       : string  = 'name';
  @Input() disabled         : string  = 'false';
  @Input() required         : string  = 'false';
  @Input() color            : string  = '';
  @Input() maxNumber        : string  = '';
  @Input() minNumber        : string  = '';
  @Input() maxRow           : string  = '3';
  @Input() minRow           : string  = '10';
  @Input() appearanceDetail : string  = '';
  @Input() suffixBehavior   : string  = '';
  @Input() suffixShowIcon   : boolean = false;
  @Input() radioOptions     : any;
  @Input() minDate          : Date | null = null;
  @Input() maxDate          : Date | null = null;
  @Input() checked          : boolean = false;
  @Input() toolTip          : string = '';
  @Input() focusClick       : boolean = false;
  suffixIcon                : string = '';
  classSuffix               : string = '';
  myControl = new UntypedFormControl();
  filteredOptions : Observable<any> | undefined;
  errorMessage    : string = '';
  errorDateFormat : boolean = false;

  // Type of input
  intputType: string[] = [
    "button",
    "color",
    "email",
    "file",
    "hidden",
    "image",
    "month",
    "password",
    "range",
    "reset",
    "submit",
    "tel",
    "text",
    "time",
    "url",
    "week"
  ];

  constructor(
    private translateService: TranslateService,
    private dateAdapter     : DateAdapter<any>
  ) {
    // Idioma para los DATEPICKER
    this.dateAdapter.setLocale(this.translateService.getDefaultLang());
  }

  ngOnInit(): void {
    // Type es password - Mostrar y ocultar pass
    if(this.type == 'password' && this.suffixBehavior === 'showPass'){
      this.suffixShowIcon = true;
      this.suffixIcon     = 'visibility';
      this.suffixBehavior = 'showPass';
    }
    // Con esto establecemos que el primer dia de la semana sea el lunes
    this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
  /*
    Filtro del autocomplete
  */
    if(this.type==='autocomplete' && this.options !== undefined){
      this.outputControl.emit(this.myControl);
      // Metemos el valor que llega
      this.myControl.setValue(this.value)
      // Los datos para los filtros a recorrer
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value[this.optionName]),
        map(value => value ? this._filter(value) : this.options.slice())
      );
    }
  /*
    FIN Filtro del autocomplete
  */
   /*
    Comprobar si el campo de fecha viene completo
  */
    if(this.type==='date' && this.value !== null){
      this.value = DateFormat(this.value, 'dd-MM-yyyy') === '' ? null : this.value;
    }
    /*
    FIN Comprobar si el campo de fecha viene completo
    */
  }

  ngOnChanges(): void{
    // Autocomplete, como disabled
    if(this.type==='autocomplete' && this.options !== undefined && this.disabled === 'true')
      this.myControl.disable()
    // Comprobar si el campo de fecha viene completo
    if(this.type==='date' && this.value !== null){
      this.value = DateFormat(this.value, 'dd-MM-yyyy') === '' ? null : this.value;
    }
  }

  onKeyup(event: any) {
    this.validateInputFormat(event.target.value);
  }

  async validateInputFormat(valueKeyup: string = ''): Promise<void> {
    this.errorDateFormat = false;
    if(this.value !== null){
      this.value = DateFormat(this.value, 'dd-MM-yyyy') === '' ? null : this.value;
    } else if (valueKeyup.length > 0) {
      this.errorDateFormat = true;
    }
  }

  selectFunction(event: MouseEvent) {
    // Se evita que se active el envio del formulario cuando se hace click en el span del input
    event.preventDefault();

    switch(this.suffixBehavior){
      case 'clear':
        this.clear();
        this.returnValue(this.value);
      break;
      case 'showPass':
        this.showPass();
      break;
      default:
      break;
    }
  }

  clear() {
    this.value = ''
  }

  showPass() {
    this.suffixIcon = this.suffixIcon === 'visibility_off' ? 'visibility' : 'visibility_off';
    this.type       = this.type == 'text' ? 'password' : 'text';
  }

  async returnValue(value: any) {
    this.inputValue.emit(value);
  }

  /*
    Input solo number
  */
    returnValueInputNumber(value: any) {
      if (this.minNumber !== '' && this.minNumber > value){
        value = this.minNumber;
      }
      if (this.maxNumber !== '' && this.maxNumber < value){
        value = this.maxNumber;
      }

      this.inputValue.emit(value);
    }
    validateInputFormatNumber(event: any) {
      validateFormatNumber(event);
    }

  /*
    Filtro del autocomplete
  */
  displayFn(option: any): string {
    if(option && option.name)
      return option.name;
    else if(option && option.value)
      return option.value;
    else
      return typeof option === "string" ? option : '';
  }
  private _filter(value: string): string[] {
    const filterValue = value.toString().toLowerCase();
    return this.options.filter((option: any) => option[this.optionName].toLowerCase().indexOf(filterValue) === 0);
  }
  /*
    FIN - Filtro del autocomplete
  */

  dateFilter = (date: Date | null): boolean => {
    if (!date) {
      return true;
    }
    if (this.minDate && date < this.minDate) {
      return false;
    }
    if (this.maxDate && date > this.maxDate) {
      return false;
    }
    return true;
  }


  onFocus(picker: MatDatepicker<any>) {
    picker.open();
  }
}
