import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {MatDatepicker} from '@angular/material/datepicker';
import { Moment} from 'moment';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';

// Definir los formatos para el componente específico
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY',  // Establece el formato que quieras para el parseo de fechas
  },
  display: {
    dateInput: 'YYYY',  // Establece el formato para la visualización de la fecha
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector    : 'app-date-year',
  templateUrl : './date-year.component.html',
  styleUrls   : ['./date-year.component.scss'],
  providers: [
    // Proveedor local de MAT_DATE_FORMATS para este componente
    provideMomentDateAdapter(MY_DATE_FORMATS),
  ]
})

export class DateYearComponent implements OnInit {
  @Output() inputValue    = new EventEmitter<any>();
  @Output() outputControl = new EventEmitter<UntypedFormControl>();
  @Input() id               : string  = '';
  @Input() type             : string  = '';
  @Input() appearance       : MatFormFieldAppearance = 'outline';
  @Input() width            : string  = '100%';
  @Input() label            : string  = '';
  @Input() hint             : string  = '';
  @Input() placeholder      : string  = '';
  @Input() value            : any     = '';
  @Input() options          : any;
  @Input() optionName       : string  = 'name';
  @Input() disabled         : boolean = false;
  @Input() required         : string  = 'false';
  @Input() color            : string  = '';
  @Input() maxNumber        : string  = '';
  @Input() minNumber        : string  = '';
  @Input() maxRow           : string  = '3';
  @Input() minRow           : string  = '10';
  @Input() appearanceDetail : string  = '';
  @Input() suffixBehavior   : string  = '';
  @Input() suffixShowIcon   : boolean = false;
  @Input() radioOptions     : any;
  @Input() minDate          : Date | null = null;
  @Input() maxDate          : Date | null = null;
  @Input() checked          : boolean = false;
  @Input() toolTip          : string = '';
  @Input() dateStartView    : string = 'only-year';
  datepickerstartView       : string = 'multi-year';
  suffixIcon                : string = '';
  classSuffix               : string = '';
  myControl = new UntypedFormControl();
  filteredOptions : Observable<any> | undefined;
  errorMessage    : string = '';
  errorDateFormat : boolean = false;

  constructor(
    private translateService: TranslateService,
    private dateAdapter     : DateAdapter<any>
  ) {
    // Idioma para los DATEPICKER
    this.dateAdapter.setLocale(this.translateService.getDefaultLang());
  }

  ngOnInit(): void {
    this.outputControl.emit(this.myControl);
    // Metemos el valor que llega
    this.myControl.setValue(this.value)
    // Con esto establecemos que el primer dia de la semana sea el lunes
    this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
  }

  async returnValue(value: Moment) {
    this.inputValue.emit(value);
  }

  clear() {
    this.value = ''
  }

  dateFilter = (date: Date | null): boolean => {
    if (!date) {
      return true;
    }
    if (this.minDate && date < this.minDate) {
      return false;
    }
    if (this.maxDate && date > this.maxDate) {
      return false;
    }
    return true;
  }

  async yearSelected(normalized: Moment, datepicker: MatDatepicker<Moment>) {
    this.outputControl.emit(this.myControl);
    // Metemos el valor que llega
    this.value = normalized;
    this.myControl.setValue(this.value)
    datepicker.close();
  }

  onFocus(picker: MatDatepicker<any>) {
    picker.open();
  }
}
