import { ContractJSON, Contract } from "../models/Contract";
import { Collection } from "../utils/Collection";
import { BASE_URL } from "../utils/Environment";
import { ApiHttpRequest } from "../utils/Http";
import { GenericRepository } from "./GenericRepository";
import { ICollection, IFilter } from "../interfaces/utilitiesInterfaces";
import { ErrorResponse } from "../utils/Response";
import { IContractSpecificMethodsRepository, IMultipleObjectCrudRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { IContract } from "../aon";
import { TaskHolder } from "../models/TaskHolder";
import { CONTRACT_URL, MESSAGE_URL } from "../utils/ApiUrls";
import { formatDate } from "../utils/Date";
import { ISalaryType, IQuoteGroup, IDayType, IWorkplace, ICcc, ICno, IContractType, IOccupation, IRlce, IWorkerCollective, IGeozone } from "../interfaces/modelsInterfaces";
import { collectionDayType } from "../models/DayType";
import { collectionSalaryType } from "../models/SalaryType";
import { QuoteGroupJSON } from "../models/QuoteGroup";
import { CccFields, ContractFields } from "../utils/ModelsFields";
import { CccFilter, ContractFilter } from "../utils/ModelsFilters";
import { CccJSON } from "../models/Ccc";
import { WorkplaceJSON } from "../models/Workplace";
import { OccupationJSON } from "../models/Occupation";
import { CnoJSON } from "../models/Cno";
import { WorkerCollectiveJSON, getWorkersCollective } from "../models/WorkerCollective";
import { ContractTypeJSON } from "../models/ContractType";
import { RlceJSON } from "../models/Rlce";
import { Geozone } from "../models/Geozone";
import { Base64toBlob, Base64ToFile } from "../utils/FileHelper"

export class ContractRepository extends GenericRepository<Contract> implements IContractSpecificMethodsRepository, IMultipleObjectCrudRepository<Contract>, ISingleObjectCrudRepository<Contract> {
    
    async getCollection(filter?: ContractFilter): Promise<ICollection<Contract>> {
        let collection = new Collection<Contract>();
        let active = filter?.fields?.get(ContractFields.STATUS) ? filter?.fields?.get(ContractFields.STATUS) : true;
        let response = await ApiHttpRequest.get(ApiHttpRequest.makeURL(BASE_URL + CONTRACT_URL.CONTRACT_LIST, generateFilter(filter)), {}, {});
        if(!(response?.type! == 'error')){
            response.forEach((element: any) => {
                collection.add(ContractJSON.parseDataToReceive(element, active));
            });
        }else {
            throw new ErrorResponse('1102');
        }
        return collection;
    }
    
    async get(key: string): Promise<Contract> {
        let response = await ApiHttpRequest.get(BASE_URL + CONTRACT_URL.ONE_CONTRACT + '?contractId=' + key, {}, {});
        if(!(response?.type! == 'error')){
            return ContractJSON.parseDataToReceiveOneContract(response);
        } else {
            throw new ErrorResponse('1103');
        }
    }

    async countContracts(filter?: ContractFilter): Promise<number> {
        let response = await ApiHttpRequest.get(ApiHttpRequest.makeURL(BASE_URL + CONTRACT_URL.CONTRACT_LIST_COUNT, generateFilter(filter)), {}, {});
        if(!(response?.type! == 'error')){
            return response;
        }
        else
            throw new ErrorResponse('1103');
    }

    async createContractRequest(contract: IContract, _taskHolder: TaskHolder, note?: string): Promise<IContract> {
        let cauInfo = await ApiHttpRequest.get(BASE_URL + MESSAGE_URL.GET_CAU, {}, {})
        let sender = await ApiHttpRequest.get(BASE_URL + MESSAGE_URL.GET_TASK_HOLDER_ONE + '?id=' + localStorage.getItem('registry'), {}, {})
        let taskHolder = await ApiHttpRequest.get(BASE_URL + MESSAGE_URL.GET_TASK_HOLDER_ONE + '?id=' + _taskHolder.Key, {}, {})
        if(_taskHolder.Key != taskHolder.id)
            throw new ErrorResponse('1104');
        let domain = {
            id: localStorage.getItem('domainId'),
            name: localStorage.getItem('domainName')
        }
        let workflow = {
            comment: "",
            domain: localStorage.getItem('domainId'),
            task_holder: sender,
            type: "opened",
            email: cauInfo.auth.email || ''
        }
        let description = {
            observation: note || '',
            cauinfo: cauInfo,
            fullTimeCheck: contract.Fulltime ? 'true' : 'false',
            salaryCheck: contract.SalaryAccordingAgreement ? 'true' : 'false',
            durationCheck: contract.Undefined ? 'true' : 'false',
            ipf: contract.Document,
            nss: contract.Nss,
            name: contract.Name,
            surname: contract.FirstSurname,
            lastSurname: contract.SecondSurname,
            fra: formatDate(contract.StartDate),
            category: contract.Category,
            gc: contract.QuoteGroup.getKey(),
            ctaCti: contract.Ccc.getCode()
            // ctaCti:, ----------------
            // regime:, ----------------
        }
        if(!contract.Undefined && contract.EndDate){
            Object.defineProperty(description, 'frb', {
                value: formatDate(contract.EndDate),
                enumerable: true
            })
        }
        if(!contract.SalaryAccordingAgreement){
            Object.defineProperty(description, 'salary', {
                value: contract.Salary,
                enumerable: true
            })
            Object.defineProperty(description, 'salaryType', {
                value: contract.SalaryType.getKey(),
                enumerable: true
            })
        }
        if(!contract.Fulltime) {
            Object.defineProperty(description, 'hour', {
                value: contract.Hours,
                enumerable: true
            })
            Object.defineProperty(description, 'jornadaType', {
                value: contract.DayType.getKey(),
                enumerable: true
            })
            if(contract.Coef != 0)
                Object.defineProperty(description, 'coef', {
                    value: contract.Coef,
                    enumerable: true
                })
        }
        let json = {
            domain: domain,
            sender: sender,
            task_holder: taskHolder,
            workgroup: {
                active: true,
                description: "",
                dirty: false,
                domain: localStorage.getItem('domainId'),
                removed: false
            },
            title: "Alta de empleado",
            registry: {},
            workflow: [workflow],
            description: JSON.stringify(description),
            status: 'pending',
            source: 'request',
            source_id: 2,
            files: [],
            project: {},
            tags: [],
            childs: [],
            domaintmp: domain,
            workflowtmp: workflow,
            mytaskholder: sender,
            auth: cauInfo.auth,
            domaincompany: cauInfo.company.domain
        }
        let response = await ApiHttpRequest.post(BASE_URL + CONTRACT_URL.CONTRACT_REQUEST, {}, json)
        if(!(response?.type! == 'error')){
            return contract;
        } else { 
            throw new ErrorResponse('1102');
        }
    }

    async getSalaryTypeList(): Promise<ICollection<ISalaryType>> {
        return collectionSalaryType;
    }

    async getQuoteGroupList(): Promise<ICollection<IQuoteGroup>> {
        let collection = new Collection<IQuoteGroup>();
        let response = await ApiHttpRequest.get(BASE_URL + CONTRACT_URL.QUOTE_GROUP, {}, {});
        if(!(response?.type! == 'error')){
            response.forEach((element: any) => {
                collection.add(QuoteGroupJSON.parseDataToReceive(element));
            })
            return collection;
        }
        else
            throw new ErrorResponse('1103');
    }

    async getDayTypeList(): Promise<ICollection<IDayType>> {
        return collectionDayType;
    }

    async getWorkplaceList(): Promise<ICollection<IWorkplace>> {
        let collection = new Collection<IWorkplace>();
        let response = await ApiHttpRequest.get(BASE_URL + CONTRACT_URL.WORKPLACE_LIST, {}, {});
        if(!(response?.type! == 'error')){
            response.forEach((element: any) => {
                collection.add(WorkplaceJSON.parseDataToReceive(element));
            })
            return collection;
        }
        else
            throw new ErrorResponse('1103');
    }

    async getCccList(filter?: CccFilter): Promise<ICollection<ICcc>> {
        let collection = new Collection<ICcc>();
        let response = await ApiHttpRequest.get(BASE_URL + CONTRACT_URL.CCC_LIST, {}, {});
        if(!(response?.type! == 'error')){
            response.forEach((element: any) => {
                collection.add(CccJSON.parseDataToReceive(element));
            })
            if(filter?.fields?.has(CccFields.GEOZONE)){
                let col = new Collection<ICcc>();
                collection.forEach(element => {
                    if(element.Geozone == filter?.fields?.get(CccFields.GEOZONE)){
                        col.add(element);
                    }
                })
                collection = col;
            }
            return collection;
        }
        else
            throw new ErrorResponse('1103');
    }

    async getTypeContractList(): Promise<ICollection<IContractType>> {
        let collection = new Collection<IContractType>();
        let response = await ApiHttpRequest.get(BASE_URL + CONTRACT_URL.CONTRACT_TYPE_LIST, {}, {});
        if(!(response?.type! == 'error')){
            response.forEach((element: any) => {
                collection.add(ContractTypeJSON.parseDataToReceive(element));
            })
            return collection;
        }
        else
            throw new ErrorResponse('1103');
    }

    async getOccupationList(): Promise<ICollection<IOccupation>> {
        let collection = new Collection<IOccupation>();
        let response = await ApiHttpRequest.get(BASE_URL + CONTRACT_URL.OCCUPATION_LIST, {}, {});
        if(!(response?.type! == 'error')){
            response.forEach((element: any) => {
                collection.add(OccupationJSON.parseDataToReceive(element));
            })
            return collection;
        }
        else
            throw new ErrorResponse('1103');
    }

    async getCnoList(): Promise<ICollection<ICno>> {
        let collection = new Collection<ICno>();
        let response = await ApiHttpRequest.get(BASE_URL + CONTRACT_URL.CNO_LIST, {}, {});
        if(!(response?.type! == 'error')){
            response.forEach((element: any) => {
                collection.add(CnoJSON.parseDataToReceive(element));
            })
            return collection;
        }
        else
            throw new ErrorResponse('1103');
    }

    async getRlceList(): Promise<ICollection<IRlce>> {
        let collection = new Collection<IRlce>();
        let response = await ApiHttpRequest.get(BASE_URL + CONTRACT_URL.RLCE_LIST, {}, {});
        if(!(response?.type! == 'error')){
            response.forEach((element: any) => {
                collection.add(RlceJSON.parseDataToReceive(element));
            })
            return collection;
        }
        else
            throw new ErrorResponse('1103');
    }

    async getWorkerCollectiveList(): Promise<ICollection<IWorkerCollective>> {
        let collection = new Collection<IWorkerCollective>();
        getWorkersCollective.forEach((element: any) => {
            collection.add(WorkerCollectiveJSON.parseDataToReceive(element));
        })
        return collection;
    }

    async getGeozoneList(): Promise<ICollection<IGeozone>> {
        let list = await this.getCccList();
        let collection: Collection<Geozone> = new Collection<Geozone>();
        for(let i = 0; i < list.size(); i++){
            let geozone = new Geozone();
            geozone.Key = list.toArray()[i].Geozone;
            geozone.Value = list.toArray()[i].Geozone;
            collection.add(geozone);
        }
        return collection;
    }

    async sendContractComunica(contract: IContract): Promise<File> {
        await ValidateComunica(contract);
        let json = await ComunicaToJson(contract);
        let response = await ApiHttpRequest.post(BASE_URL + CONTRACT_URL.ALTA_DIRECTA, {}, json);
        if(!(response?.type! == 'error')) {
            let blob: Blob = Base64toBlob(response.file);
            let file: File = new File([blob], contract.Document, {
                type: "application/pdf",
              });
            return file;
        }
        else throw new ErrorResponse('1103');
    }

}

let generateFilter = (filter?: ContractFilter) => {
    let page = filter?.pageNum ? filter?.pageNum : 1;
    let perPage = filter?.pageItems ? filter?.pageItems : 20;
    let status = filter?.fields?.get(ContractFields.STATUS) ? filter?.fields?.get(ContractFields.STATUS) : undefined;
    let from = filter?.fields?.get(ContractFields.START_DATE) ? filter?.fields?.get(ContractFields.START_DATE) : '';
    let to = filter?.fields?.get(ContractFields.END_DATE) ? filter?.fields?.get(ContractFields.END_DATE) : '';
    let name = filter?.fields?.get(ContractFields.NAME) ? filter?.fields?.get(ContractFields.NAME) : '';
    let workplace = filter?.fields?.get(ContractFields.WORKPLACE) ? filter?.fields?.get(ContractFields.WORKPLACE) : '';
    let ccc = filter?.fields?.get(ContractFields.CCC) ? filter?.fields?.get(ContractFields.CCC) : '';
    let global = filter?.fields?.get(ContractFields.GLOBAL) ? filter?.fields?.get(ContractFields.GLOBAL) : '';
    let json = {
        page: page,
        perPage: perPage,
    };
    if(status != undefined){
        Object.defineProperty(json, 'status', {
            value: status,
            enumerable: true
        });
    }
    if(from != '')
        Object.defineProperty(json, 'from', {
            value: new Date(from).toISOString(),
            enumerable: true
        });
    if(to != '')
        Object.defineProperty(json, 'to', {
            value: new Date(to).toISOString(),
            enumerable: true
        });
    if(name != '')
        Object.defineProperty(json, 'name', {
            value: name,
            enumerable: true
        });
    if(workplace != '')
        Object.defineProperty(json, 'workplace', {
            value: workplace,
            enumerable: true
        });
    if(ccc != '')
        Object.defineProperty(json, 'code', {
            value: ccc,
            enumerable: true
        });
    if(global != '')
        Object.defineProperty(json, 'global', {
            value: global,
            enumerable: true
        });
    return json;
}

let ValidateComunica = async (contract: IContract) => {
    let repo = new ContractRepository();
    let cccList = await repo.getCccList();
    let typeList = await repo.getTypeContractList();
    let quoteGroup = await repo.getQuoteGroupList();
    if(!cccList.exists(contract.Ccc.getKey().toString())) 
        throw new ErrorResponse("2302");
    if(contract.StartDate == null || contract.StartDate == new Date(0))
        throw new ErrorResponse("2303");
    if(contract.Nss == "") 
        throw new ErrorResponse("2304");
    if(contract.Document == "") 
        throw new ErrorResponse("2305");
    if(!typeList.exists(contract.TypeId).toString() || contract.TypeId == "") 
        throw new ErrorResponse("2306");
    if(!quoteGroup.exists(contract.QuoteGroup.getKey().toString()))
        throw new ErrorResponse("2307");
}

let ComunicaToJson = async (contract: IContract) => {
    let repo = new ContractRepository();
    let ocupations = await repo.getOccupationList();
    let cnos = await repo.getCnoList();
    let dayTypes = await repo.getDayTypeList();
    let collectives = await repo.getWorkerCollectiveList();
    let rlces = await repo.getRlceList();
    let json: { [key: string]: any } = {};
    if(contract.WorkCenter != "") json.workplace = contract.WorkCenter;
    json.ctaCti = contract.Ccc.Code;
    json.regime = contract.Ccc.RegimeCode;
    json.nss = contract.Nss;
    json.ipf = contract.Document;
    if(contract.Name != "") json.name = contract.Name;
    if(contract.FirstSurname != "" && contract.SecondSurname != "") {
        json.apellido1 = contract.FirstSurname;
        json.apellido2 = contract.SecondSurname;
    }
    if(contract.EndDate != null) json.fecha = formatDate(contract.EndDate);
    json.contract = contract.TypeId;
    json.gc = contract.QuoteGroup.getKey();
    if(rlces.exists(contract.Rlce.getKey()) && contract.Rlce.getKey() != "") json.rlce = contract.Rlce.getKey();
    if(ocupations.exists(contract.Occupation.getKey())) json.ocup = contract.Occupation.getKey();
    if(cnos.exists(contract.Cno.getKey())) json.cno = contract.Cno.getKey();
    if(dayTypes.exists(contract.DayType.getKey())) json.tipo_jornada = contract.DayType.getKey();
    if(collectives.exists(contract.WorkerCollective.getKey())) json.collective = contract.WorkerCollective.getKey();
    if(contract.TypeId != null && (contract.TypeId.toString().charAt(0) == "2" || contract.TypeId.toString().charAt(0) == "3" || contract.TypeId.toString().charAt(0) == "5") && contract.Coef != 0 && contract.Hours != 0){
        json.coef = contract.Coef * 1000;
        json.horas_convenio = contract.Hours;
    }
    json.situation = "AL";
    return json;
}