<div class="modal-container-send">
  <div class="issue">
    <div>
      <span *ngIf="false" class="title">{{ "COMMON.UPLOAD.FOLDER" | translate }}</span>
    </div>
    <app-icon
      class="close-button pointer padding-bottom"
      shape="close"
      (click)="closeModal()"
    ></app-icon>
  </div>
  <div class="formSend">
    <app-input
      [class]="'inputSelect'"
      [type]="'select'"
      [label]="'COMMON.UPLOAD.FOLDER' | translate"
      [options]="folderList"
      (inputValue)="folder($event)"
      [value]="folderSelected"
      [required]="'required'"
    ></app-input>
    <label
      id="upload-box"
      [ngClass]="{'drag-over': isDropOver}"
      *ngIf="filesAndFolder.length === 0"
      (drop)="onDrop($event)"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
    >
      <input
        multiple
        type="file"
        (change)="onFileSelected($event)"
      />
      {{ "COMMON.FILE_UPLOAD_BUTTON" | translate }}
      <app-icon shape="cloud_upload" class="margin-left"></app-icon>
    </label>
    <p *ngIf="this.folderSelected === '' && filesAndFolder.length > 0" class="red">
      {{ "COMMON.UPLOAD.NOT_FOLDER" | translate }}
    </p>
    <div id="documentCharge" *ngIf="filesAndFolder.length > 0">
      <div class="document">
        <app-card
          [class]        = "'file pink'"
          *ngFor         = "let file of filesAndFolder; let i = index"
          cardMatTooltip = "{{ file['document'].name }}"
        >
          <div header>
            <div class = "cancel">
              <app-icon
                shape   = "close"
                (click) = "clearDocument(i)"
              ></app-icon>
            </div>
          </div>
          <div body>
            <span class="icon-background">
              <app-icon [shape]="'picture_as_pdf'"></app-icon>
            </span>
          </div>
          <div footer>
            <div
              class="bold"
            >
              {{ file['document'].name }}
            </div>
            <div class="griss">{{ currentDate }}</div>
            <div class="griss">{{ currentDateHour }}</div>
          </div>
        </app-card>
      </div>
    </div>
  </div>
  <div class="buttonsUpload" *ngIf="filesAndFolder.length > 0">
    <app-button
      [class]="'pink'"
      [width]="'10rem'"
      [matDialogClose]="filesAndFolder"
    >
      <app-icon shape="aon_upload"></app-icon>
      {{ "COMMON.UPLOAD.UPLOAD" | translate }}
    </app-button>
  </div>
</div>
