<mat-form-field
  [appearance]="appearance"
  [ngStyle]="{
    'width': width
  }"
  [class]="appearanceDetail"
  floatLabel="always"
>
  <mat-label [hidden]="label === ''">{{label}}</mat-label>
  <span matPrefix><ng-content select="[prefix]"></ng-content></span>

  <!-- INPUTS TIPO DATEPICKER -->
  <div
    *ngIf="(type === 'date' || type === 'datetime-local') && datepickerstartView !== '' && datepickerstartView === 'month' || datepickerstartView === 'year' || datepickerstartView === 'multi-year'"
    class="datepicker"
  >
    <span *ngIf="this.dateStartView === 'only-year'">
      <input
        matInput
        [matDatepicker]="picker"
        [ngClass]="['ng-pending']"
        [formControl]="myControl"
        (ngModelChange)="returnValue($event)"
        [required]="required"
        [placeholder]="placeholder"
        [class]="appearanceDetail"
        [min]="minDate"
        [max]="maxDate"
        readonly
        (click)="onFocus(picker)"
        (focus)="onFocus(picker)"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker
        [startView]="datepickerstartView"
        (yearSelected)="yearSelected($event, picker)"
        #picker
      ></mat-datepicker>
    </span>
  </div>
  <!-- FIN INPUTS TIPO DATEPICKER -->

  <mat-error *ngIf="required == 'required' && !errorDateFormat">
    {{label}}&nbsp;<span [innerHTML]="'COMMON.INPUT.REQUIRE' | translate"></span>
  </mat-error>

  <mat-error *ngIf="type === 'date' && errorDateFormat">
    <span [innerHTML]="'COMMON.INPUT.FORMAT' | translate"></span>
  </mat-error>

  <mat-hint *ngIf="hint != ''">{{hint}}</mat-hint>
</mat-form-field>
