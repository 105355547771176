import { Component, OnInit, EventEmitter, Output, ViewChild, Input} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from 'src/app/core/services/auth.service';
import { AonPermission } from 'libraries/AonSDK/src/aon';
import { MenuButton } from 'src/app/core/models/interface/menu-button';
import { OptionsService } from '../../services/options.service';
import { Shortcut } from 'src/app/core/models/interface/shortcut';
import { ModalCreateComponent } from 'src/app/modules/inbox/components/modal-create/modal-create.component';
import { globalRouterlink, globalRouterlinkBase, globalRouterlinkSections } from 'src/app/app.globals';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  items: MenuButton[] = [];
  shortcuts: Shortcut[] = [];
  opened: boolean;

  currentRoute: string = this.router.url.replace('/', '').split('/')[0];
  subMenuOpened: boolean;
  @Input() hide: boolean;
  @Output() onSelected = new EventEmitter<any>();
  @ViewChild('iconHover') iconHover: any;
  @ViewChild('modalCreateInbox') modalCreateInboxComponent: any = '';
  functionHome: any = (result: any) => this.afterModalClosed(result);
  selectedMenuItemClass: string = '';

  constructor(
    private authService     : AuthService,
    private optionsService  : OptionsService,
    private router          : Router,
    private translateService: TranslateService
  ) {
    this.hide = false;
    this.opened = false;
    this.subMenuOpened = false;

    this.router.events.subscribe((event) => {
      event instanceof NavigationEnd ? this.checkCurrentRoute() : null;
    });

    this.translateService.get([
      'MENU.INBOX',
      'MENU.BILLING',
      'MENU.TAX_PANEL',
      'MENU.EMPLOYEE_PANEL',
      'MENU.DOCUMENTATION',
      'HOME.CREATE_INVOICE',
      'EMPLOYEE_PANEL.MARK',
      'INBOX.CREATE_QUERY',
      'HOME.MY_LASTEST_INVOICES',
      'EMPLOYEE_PANEL.REGISTER_EMPLOYEE'
    ]).subscribe((result) => {
      this.authService.getAllPermissions().then((permissions) => {
      //
      // Tiene el modulo = AonPermission.MESSENGER
      //
        // if(permissions.Permissions.includes(AonPermission.MESSENGER)){
          // Boton apartado notificaciones
          // this.items.push({routerlink: globalRouterlink['inbox'], shape: 'inbox', text: result['MENU.INBOX'],  class: 'red', selected: false});
          // Crear Notificacion
//          this.shortcuts.push({routerlink: globalRouterlinkSections['inbox/create'], shape: 'add_comment', toolTip: result['INBOX.CREATE_QUERY'], options: { showModal: true }});
        // }
      //
      // Tiene los modulos =
      //    AonPermission.INVOICE_PORTAL ||
      //    AonPermission.BANK
      //
        if(
          permissions.Permissions.includes(AonPermission.INVOICE_PORTAL) ||
          permissions.Permissions.includes(AonPermission.BANK)
        ){
          // Boton apartado gestion
          // si tengo permiso de banco me muestra la vista management, si no tengo va directamente a facturación
          // if (permissions.Permissions.includes(AonPermission.BANK)) {
            // this.items.push({ routerlink: globalRouterlinkBase['management'], shape: 'assessment', text: result['MENU.BILLING'], class: 'blue', selected: false });
          // }
          // else {
            this.items.push({ routerlink: globalRouterlink['management'], shape: 'assessment', text: result['MENU.BILLING'], class: 'blue', selected: false });
          // }
          // Ver mis últimas facturas si tengo permiso de banco y laboral
          // if(permissions.Permissions.includes(AonPermission.BANK) && permissions.Permissions.includes(AonPermission.INVOICE_PORTAL)){
            // this.shortcuts.push({routerlink: globalRouterlinkSections['sales'], shape: 'receipt', toolTip: result['HOME.MY_LASTEST_INVOICES']});
          // }
          // Crear Factura
          if(permissions.Permissions.includes(AonPermission.INVOICE_PORTAL)){
            this.shortcuts.push({routerlink: globalRouterlinkSections['sales/create/factura'], shape: 'add_box', toolTip: result['HOME.CREATE_INVOICE']});
          }
        }
      //
      // Tiene el modulo = AonPermission.FISCAL
      //
        if(permissions.Permissions.includes(AonPermission.FISCAL)){
          // Boton apartado panel de impuestos
          this.items.push({routerlink: globalRouterlink['tax-panel'], shape: 'euro_symbol', text: result['MENU.TAX_PANEL'], class: 'orange', selected: false});
        }
      //
      // Tiene el modulo = AonPermission.PAYROLL_PORTAL
      //
      //  if(permissions.Permissions.includes(AonPermission.PAYROLL_PORTAL)){
        // Boton apartado empleados
        // this.items.push({routerlink: globalRouterlink['employees'], shape: 'people', text: result['MENU.EMPLOYEE_PANEL'],  class: 'green', selected: false});
        // Crear Empleado
        // this.shortcuts.push({routerlink: globalRouterlinkSections['employees/create'], shape: 'person_add', toolTip: result['EMPLOYEE_PANEL.REGISTER_EMPLOYEE']});
      // }
      //
      // Tiene el modulo = AonPermission.TIMECONTROL_PORTAL
      //
       if(permissions.Permissions.includes(AonPermission.TIMECONTROL_PORTAL)){
         // Crear marcaje
        //  this.shortcuts.push({routerlink: globalRouterlinkSections['employees/mark'], shape: 'alarm', toolTip: result['EMPLOYEE_PANEL.MARK']});
       }
      //
      // Tiene el modulo = AonPermission.DOCUMENTAL
      //
        if(permissions.Permissions.includes(AonPermission.DOCUMENTAL)){
          // Boton apartado documentacion
          this.items.push({routerlink: globalRouterlink['documentation'], shape: 'description', text: result['MENU.DOCUMENTATION'], class: 'pink', selected: false});
        }
      //
      // Tiene el modulo = AonPermission.
      //
//        if(permissions.Permissions.includes(AonPermission.)){
//          // Boton apartado consulting
//          this.items.push({routerlink: globalRouterlink['consulting'],      shape: 'work',        text: 'ASESORÍA',           class: 'purple',  selected: false});
//        }
      });
    });
  }

  ngOnInit(): void {
    this.items.forEach((element) => {
      if (element.routerlink == this.currentRoute) {
        element.selected = true;
      }
    });
  }

  setOpened(state: boolean) {
    this.opened = state;
  }

  // Función para marcar o desmarcar items
  checkCurrentRoute() {
    let exist = false;
    this.items.forEach((element) => {
      if (this.router.url.split('/', 2)[1] === element.routerlink.replace("/", "")) {
        // marcamos en el que estamos
        element.selected = true;
        exist = true;
      }else if (element.selected === true) {
        // desmarcamos por si estabamos en el
        element.selected = false;
      }
    });
    if (!exist) {
      this.deselectAll();
    }
  }

  // Función para redirigir a sales dejando en items billing para el marcado en blue
  navigate(routerLink: string) {
    if (routerLink === globalRouterlinkSections['management']) {
      this.router.navigateByUrl(globalRouterlinkSections['billing/sales']);
    } else {
      this.router.navigateByUrl(routerLink);
    }
  }

  select(item: MenuButton) {
    if ( item.routerlink.startsWith('/'))
      item.routerlink = item.routerlink.substring(1);

    this.items.forEach((element) => {

      if (element.routerlink == item.routerlink) {
        // Si seleccionamos una vista, volvemos a reducir
        if (this.opened === true) this.opened = false;
        // La ruta seleccionada
        element.selected = true;
      }
    });
  }

  afterModalClosed(result?: any) {
    console.log(result);
  }

  showModal() {
    this.modalCreateInboxComponent.openDialog(
      ModalCreateComponent,
      this.functionHome
    );
  }

  deselectAll() {
    this.items.forEach((element) => {
      element.selected = false;
    });
  }

  setOptions(data: any) {
    this.optionsService.setOptions(data.options);
  }
}
