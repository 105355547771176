import { IFilter, ICollection } from "../interfaces/utilitiesInterfaces";
import { Folder, FolderJSON } from "../models/Folder";
import { Collection } from "../utils/Collection";
import { GenericRepository } from "./GenericRepository";
import { apiFolders } from "../models/Folder";
import { IMultipleObjectCrudRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { ApiHttpRequest } from "../utils/Http";
import { BASE_URL } from "../utils/Environment";
import { DOCUMENT_URL } from "../utils/ApiUrls";
import { FolderFilter } from "../utils/ModelsFilters";
import { FolderFields } from "../utils/ModelsFields";
import { MainFolders } from "../aon";

export class FolderRepository extends GenericRepository<Folder> implements IMultipleObjectCrudRepository<Folder>, ISingleObjectCrudRepository<Folder> {
    async getCollection(filter?: FolderFilter): Promise<ICollection<Folder>> {
        let collection: ICollection<Folder> = new Collection<Folder>();
        if((filter?.fields?.has(FolderFields.PARENT) && filter?.fields?.get(FolderFields.PARENT) == "") || !filter?.fields?.has(FolderFields.PARENT)){
            let upload = filter?.fields?.has(FolderFields.UPLOAD) ? filter?.fields?.get(FolderFields.UPLOAD)[0] : undefined;
            for(let folder of apiFolders){
                if(upload != undefined && upload == true && folder.Uploadable == true){
                    collection.add(folder)
                } else if(upload != undefined && upload == false && folder.Uploadable == false){
                    collection.add(folder)
                } else if(upload == undefined){
                    collection.add(folder)
                }
            }
        }else if(filter?.fields?.has(FolderFields.PARENT) && filter?.fields?.get(FolderFields.PARENT) == MainFolders.LABORAL){
            let data = await ApiHttpRequest.get(BASE_URL + ApiHttpRequest.makeURL( DOCUMENT_URL.LABORAL ,generateLaboralFolderFilters(filter)), {}, {})
            if(data)
                data.forEach((element:any) => {
                    collection.add(FolderJSON.parseDataToReceive(element))
            });
        }
        return collection;
    }
    async getLaboralFolderCount(filter?: FolderFilter):Promise <number>{
        let response = await ApiHttpRequest.get(BASE_URL + ApiHttpRequest.makeURL('/ms/api/contract-api/count', generateLaboralFolderFilters(filter)),{},{});
        return response
    }
}
    let generateLaboralFolderFilters = (filter?: FolderFilter) => {
        let page = filter?.pageNum || 1;
        let perPage = filter?.pageItems || 10;
        let global = filter?.fields?.get(FolderFields.GLOBAL) || '';
        let params = {
         page: page,
         perPage: perPage,
         domain: localStorage.getItem("domainId")
        }
        if(global != ''){
         Object.defineProperty(params, "global",{ 
            value: global,
            enumerable: true
        });
    }
    return params;
}